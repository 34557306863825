.container {
  > * {
    margin-bottom: 0.5rem;
  }
}

.ownedPunks {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  height: 160px;
}

.recentlyMined {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  height: 80px;
}

.inputContainer {
  > input {
    width: 300px;
  }
}

.exploreCopy {
  padding: 0.5rem;
  width: 0;
  min-width: 50%;
}
